//@
@import "react-big-calendar/lib/css/react-big-calendar.css";
@import 'react-quill/dist/quill.snow.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'League Spartan', sans-serif !important;
}

html,
#root {
  height: 100%;
  scrollbar-color: #bc2b2a #fff !important;
  scrollbar-width: thin !important;
  font-family: 'Spartan', sans-serif !important;
  color: #333;
}

#root {
  display: flex;
  z-index: 1200 !important;
  overflow: hidden;
}

#wrapper {
  flex: 1 1 auto;
}

body {
  height: 100%;
  font-family: 'Spartan', sans-serif !important;
  color: #333;
}

main {
  overflow-y: auto;
  height: calc(100% - 64px);
}

.loginMain {
  height: 100% !important;
}

main::-webkit-scrollbar,
.scrollbar {
  width: 11px;
  background-color: #fff;
}

main::-webkit-scrollbar-track,
.scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

main::-webkit-scrollbar-thumb,
.scrollbar-thumb {
  background-color: #bc2b2a;
}

.table-actions {
  flex-wrap: nowrap !important;
  justify-content: flex-end;
  display: flex;
}

div[role=dialog],
div[role=presentation] {
  z-index: 99999 !important;
}

.no-padding {
  padding: 0 !important;
  padding-bottom: 0 !important;
}

.no-padding>section {
  padding: 0
}

.quill {
  height: fit-content;
}

.ql-editor {
  height: 150px;
  overflow-wrap: anywhere;
}

.filter-inputs {
  input:not([value=""]) {
    background-color: rgba(188, 43, 42, 0.08);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.rotate {
  -webkit-animation: rotate 3s normal linear infinite;
  animation: rotate 3s normal linear infinite;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }

  25% {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
  }

  50% {
    -webkit-transform: rotate3d(0, 0, 1, 180deg);
    transform: rotate3d(0, 0, 1, 180deg);
  }

  75% {
    -webkit-transform: rotate3d(0, 0, 1, 270deg);
    transform: rotate3d(0, 0, 1, 270deg);
  }

  100% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
    transform: rotate3d(0, 0, 1, 360deg);
  }
}


$bg: #fff;
$barsize: 15px;


.hr {
  width: 100%;
  height: 2px;
  display: block;
  position: relative;
  margin-bottom: 0em;
  padding: 1em 0;

  &:after,
  &:before {

    content: "";
    position: absolute;

    width: 100%;
    height: 3px;
    bottom: 50%;
    left: 0;

  }

  &:before {

    background: linear-gradient(90deg, $bg 0%, $bg 50%, transparent 50%, transparent 100%);
    background-size: $barsize;
    background-position: center;
    z-index: 1;

  }

  &:after {

    transition: opacity 0.3s ease, animation 0.3s ease;

    background: linear-gradient(to right,
        #bc2b2a 5%,
        #20446b26 15%,
        #E6E6E6 25%,
        #ABAFB3 35%,
        #323232 45%,
        #bc2b2a 55%,
        #20446b26 65%,
        #E6E6E6 75%,
        #ABAFB3 85%,
        #bc2b2a 95%);

    background-size: 200%;
    background-position: 0%;
    animation: bar 15s linear infinite;

  }

  @keyframes bar {

    0% {
      background-position: 0%;
    }

    100% {
      background-position: 200%;
    }

  }

}




.hr.anim {
  &:before {
    background: linear-gradient(90deg,
        $bg 0%, $bg 5%,
        transparent 5%, transparent 10%,
        $bg 10%, $bg 15%,
        transparent 15%, transparent 20%,
        $bg 20%, $bg 25%,
        transparent 25%, transparent 30%,
        $bg 30%, $bg 35%,
        transparent 35%, transparent 40%,
        $bg 40%, $bg 45%,
        transparent 45%, transparent 50%,
        $bg 50%, $bg 55%,
        transparent 55%, transparent 60%,
        $bg 60%, $bg 65%,
        transparent 65%, transparent 70%,
        $bg 70%, $bg 75%,
        transparent 75%, transparent 80%,
        $bg 80%, $bg 85%,
        transparent 85%, transparent 90%,
        $bg 90%, $bg 95%,
        transparent 95%, transparent 100%);

    background-size: $barsize * 10;
    background-position: center;
    z-index: 1;

    animation: bar 15s linear infinite;

  }
}

.error-background {
  border-width: 2px;
  border-style: solid;
  animation-name: errorBackground;
  animation-duration: 2s;
  animation-iteration-count: 5;
}

@keyframes errorBackground {
  0% {
    background-color: white;
  }

  100% {
    background-color: rgba(255, 0, 0, 0.3);
  }
}

.error-border {
  border-width: 2px;
  border-style: solid;
  border-color: white !important;
  animation-name: errorBorder;
  animation-duration: 0.8s;
  animation-iteration-count: 3;
}

@keyframes errorBorder {
  0% {
    border-color: white !important;
  }

  100% {
    border-color: red !important;
  }
}

@media(max-width: 1024px) {
  .valuation-button {
    display: none;
  }
}
