.MuiPopover-root {
    z-index: 1400 !important;
  }

  .MuiCardHeader-action {
    margin: unset !important;
  }

  .MuiDialogContent-dividers {
  //  max-height: 800px !important; ne
  }

  .MuiButton-startIcon {
    // margin-bottom: 2px;
  }

  .MuiFormControl-marginNormal {
    margin-top: unset !important;
  }

  // .MuiDialog-paperWidthSm {
  //   max-width: 570px !important;
  // }

  .MuiPickersBasePicker-containerLandscape {
    flex-direction: column !important;
  }

  .MuiPickersToolbar-toolbarLandscape {
    max-width: unset !important;
  }

  .MuiCardContent-root:last-child {
    padding-bottom: 16px;
  }

  .MuiCardContent-root > div:nth-child(odd) .MuiPaper-root.custom_card{
    background: #fff;
  }

  // .error-border {
  //   border-color: red;
  //   border-width: 2px;
  // }

  // .MuiToolbar-regular {
  //   min-height: 56px !important;
  // }

  .MuiDialog-paperScrollPaper {
    max-height: 100% !important;
  }

  // .MuiOutlinedInput-root {
  //   min-width: 110px !important;
  // }

  .MuiDialogContent-dividers {
    overflow: auto;
    height: 100%;
  }

  .MuiDialogActions-root {
    padding: 16px 24px !important;
  }

  .MuiButton-containedPrimary.Mui-disabled {
    background: rgba(188255, 43, 42, 0.15) !important;
    color: #fff !important;
  }

  .MuiButton-containedPrimary {
    background: #bc2b2a !important;
    color: #fff !important;
    font-weight: 560;
  }

  .MuiListItemText-primary {
    font-size: 0.875rem !important;
  }

  // a.MuiTab-textColorPrimary  {
  //   width: 100%;
  // }

  .MuiAccordionSummary-content {
    margin: 0 !important;
  }

  .MuiAccordionSummary-gutters {
    padding: 0 !important;
  }

  .MuiTableHead-root {
    background: #fff;
  }

  @media all and (min-width: 0) and (max-width: 509px) {
    .MuiTablePagination-toolbar {
      padding-right: unset;
      flex-wrap: wrap;
      justify-content: center;
      padding-bottom: 32px !important;
      padding-left: unset !important;
    }
    .MuiTablePagination-input {
      margin-right: unset !important;
    }

    .MuiToolbar-gutters {
      // padding: unset !important;
    }

    .MuiToolbar-gutters .MuiGrid-root {
      // justify-content: center !important;
    }
  }

  @media all and (min-width: 0) and (max-width: 1317px) {

  .header-btn button span {
    margin-right: unset !important;
  }
  }

  @media all and (min-width: 0) and (max-width: 470px) {
    .MuiOutlinedInput-root {
      min-width: auto !important;
    }
  }

  @media all and (min-width: 0) and (max-width: 1200px) {
    .fundamental-info {
      grid-template-columns: 100% 0fr !important;
    }
    .fundamental-info-custom {
      display: flex !important;
      flex-wrap: wrap;
    }
    .search-bar {
      max-width: 100px;
      min-width: unset !important;
    }
  }
